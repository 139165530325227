import {AxiosResponse} from "axios";
import {Axios} from "config/axiosFetcher";
import {IDocumentSchema} from "schemas/documentUpdateSchema";
import {
  IAguiaPartialPhoto,
  IAnalysisResult,
  IAnalysisSendResult,
  ICalculatorInput,
  ICapsule,
  IDocument,
  IInvalidMotives,
  ILotAlert,
  IPayloadApply,
  IQueue,
  IQuiz,
  IResultCapsule,
} from "types";


const openExamsList = async () => {
  try {
    const {data}: AxiosResponse<IQueue[]> = await Axios.get("/report-software-service/v1/open-exams")
    return {data: data, err: null};
  } catch (err) {
    return {data: null, err};
  }
}

const openExam = async (code: string) => {
  try {
    const {data}: AxiosResponse<ICapsule> = await Axios.get(`/report-software-service/capsules/v1/?code=${code}`)
    return {data: data, err: null};
  } catch (err) {
    return {data: null, err};
  }
}

const openDocument = async (code: string) => {
  try {
    const {data}: AxiosResponse<IDocument> = await Axios.get(`/report-software-service/patients/v1/?code=${code}`)
    return {data: data, err: null};
  } catch (err) {
    return {data: null, err};
  }
}
const updateDocument = async (document: IDocumentSchema, oldPatientDocument: IDocumentSchema, updateResponsible: string) => {
  const payload = {
    ...document,
    oldPatientDocument,
    updateResponsible
  }

  try {
    const body = {
      ...payload
    }
    const {status}: AxiosResponse<any> = await Axios.put(`/report-software-service/patients/v1/`, body)
    return {data: status, err: null};
  } catch (err) {
    return {data: null, err};
  }
}

const getModificationHistory = async (document: string, country: string) => {
  try {
    const {data}: AxiosResponse<any> = await Axios.get(`/report-software-service/patients/v1/modification-history?document=${document}&country=${country}`);
    return {data: data, err: null};
  } catch (err) {
    return {data: null, err};
  }
}

const saveCustomObservation = async (code: string, formValue: any) => {
  try {
    const body = {
      form: formValue
    }

    const {data}: AxiosResponse<any> = await Axios.put(`/report-software-service/capsules/v1/saveCustomObservation?code=${code}`, body)
    return {data: data, err: null};
  } catch (err) {
    return {data: null, err};
  }
}

const calculateResult = async (device: string, payload: IPayloadApply, automatic: boolean) => {
  try {

    const {data}: AxiosResponse<any> = await Axios.put(`/report-software-service/calculator/v1/${device}?automatic=${automatic}`, payload)

    return {data: data, err: null};
  } catch (err: any) {
    return {data: null, err};
  }
}

const manualAnalysis = async (code: string, index: string | string[], value: string | number) => {
  try {
    const {
      status,
      data
    }: AxiosResponse<any> = await Axios.put(`/report-software-service/calculator/v1/manualAnalysis?code=${code}&index=${index}&value=${value}`)
    return {data, status, err: null};
  } catch (err) {
    return {data: null, err};
  }
}
const v2ManualAnalysis = async (code: string, input: ICalculatorInput) => {
  try {
    const {
      status,
      data
    }: AxiosResponse<any> = await Axios.put(`/report-software-service/calculator/v2/manualAnalysis?code=${code}`, {
      ...input
    })
    return {data, status, err: null};
  } catch (err) {
    return {data: null, err};
  }
}

const reprocessEagle = async (code: string) => {
  try {
    const {data}: AxiosResponse<any> = await Axios.put(`/report-software-service/calculator/v1/aguia/reprocess?code=${code}`)
    return {data: data, err: null};
  } catch (err) {
    return {data: null, err};
  }
}

const getQuiz = async (code: string) => {
  try {

    const {data}: AxiosResponse<IQuiz[]> = await Axios.get(`/quiz/answers/v1/-/getCapsuleAnswer?capsule=${code}`)
    return {data, err: null};
  } catch (err) {
    return {data: null, err};
  }
}

const getLotAlert = async (code: string) => {
  try {
    const {data}: AxiosResponse<ILotAlert[]> = await Axios.get(`/report-software-service/capsules/v1/lotAlert?code=${code}`);
    return data;
  } catch (err) {
    throw err;
  }
}

const getSample = async (code: string) => {
  try {

    const { data }: AxiosResponse<IResultCapsule> = await Axios.get(`/report-software-service/capsules/v1/sample?code=${code}`)
    return { data, err: null };
  } catch (err) {
    return {data: null, err};
  }
}

const getInvalidMotives = async (
  {
    examType,
    language,
  }: {
    examType: string,
    language: string
  }) => {
  try {
    const {data}: AxiosResponse<IInvalidMotives[]> = await Axios.get(`/report-software-service/capsules/v1/exam-interferers?examType=${examType}&type=INVALID_INTERFERERS&language=${language}`)
    return {data, err: null};
  } catch (err) {
    return {data: null, err};
  }
}

const getDocumentInvalidMotives = async () => {
  try {

    const {data}: AxiosResponse<string[]> = await Axios.get(`/report-software-service/patients/v1/getInvalidDocumentInfo`)
    return {data, err: null};
  } catch (err) {
    return {data: null, err};
  }
}

interface IHelpersDocument {
  code: string,
  type: "CAPSULE" | "DOCUMENT"
  help?: boolean,
  helpUser?: string
}


const helpService = async ({code, type, help, helpUser}: IHelpersDocument) => {
  let params = `unlock?code=${code}&type=${type}&help=${help}`;

  if (helpUser) {
    params += `&helpUser=${helpUser}`
  }
  try {
    const {data}: AxiosResponse<any> = await Axios.put(`/report-software-service/v1/${params}`)
    return {data: data, err: null};
  } catch (err) {
    return {data: null, err};
  }
}

const deleteExam = async (capsule: string, clusterId: string) => {
  try {
    const {status}: AxiosResponse<any> = await Axios.delete(`/report-software-service/v1/?capsule=${capsule}&clusterId=${clusterId}`)
    return {data: status, err: null};
  } catch (err) {
    return {data: null, err};
  }
}

const getAllReportQueues = async () => {

  try {
    const {data}: AxiosResponse<string[]> = await Axios.get(`/report-software-service/v1/get-report-queues`)
    return {data, err: null};
  } catch (err) {
    return {data: ["REPORT_BROADCAST"], err};
  }
}
const getPartialPhotos = async (code: string, type: "MSG_PARTIAL_RESULT" | "MSG_PARTIAL_STAGE_RESULT") => {
  try {
    const {data}: AxiosResponse<IAguiaPartialPhoto[]> = await Axios.get(`/report-software-service/calculator/v1/aguia/partialPhotos?code=${code}&type=${type}`)
    return {data, err: null}
  } catch (err) {
    return {data: null, err}
  }
}

const checkIsLocalAnalysis = async (code: string) => {
  try {

    const {data}: AxiosResponse<Record<string, string> | null> = await Axios.get(`/report-software-service/capsules/v1/check-local-analysis?code=${code}`)
    return data;
  } catch (err) {
    return null;
  }
}

const sendToLocalAnalysis = async (code: string, interferers?: any) => {

  const body = {
    "interferers": interferers
  }

  try {
    const {data}: AxiosResponse<Record<string, string> | null> = await Axios.put(`/report-software-service/capsules/v1/send-to-local-analysis?code=${code}`, body)
    return data;
  } catch (err) {
    return null;
  }
}

const checkIsTraining = async (code: string) => {
  try {

    const { data }: AxiosResponse<Record<string, boolean> | null> = await Axios.get(`/report-software-service/capsules/v1/check-training?code=${code}`)
    return data;
  } catch (err) {
    return null;
  }
}
const calculateAnalysisSample = async (
  code: string,
  order: number,
  results: IAnalysisResult[],
  removeBaseline?: boolean
) => {
  try {
    const { data } = await Axios.post<IAnalysisSendResult>(
      `/report-software-service/validation/v1/-/exam/${code}/calculate`,
      {
        code,
        order,
        results,
        removeBaseline
      }
    );
    return {data: data, err: null};
  } catch (err: any) {
    return {data: null, err};
  }
};


export {
  openExamsList,
  openExam,
  openDocument,
  helpService,
  updateDocument,
  calculateResult,
  saveCustomObservation,
  manualAnalysis,
  getQuiz,
  getLotAlert,
  getSample,
  getInvalidMotives,
  getDocumentInvalidMotives,
  deleteExam,
  getAllReportQueues,
  reprocessEagle,
  v2ManualAnalysis,
  getPartialPhotos,
  checkIsLocalAnalysis,
  sendToLocalAnalysis,
  getModificationHistory,
  checkIsTraining,
  calculateAnalysisSample
}

